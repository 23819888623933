import packageVersion from "../../../package.json";

export const environment = {
    production: false,
    isLocal: false,
    withoutAdmin: false,
    apiServer: {
        host: 'https://api-dev.bigvu.tv/',
        version: 'v0.5/',
    },
    firebase: {
        apiKey: "AIzaSyC6sNBk9vhvGk3sz8AAU5k4hf4khAU8r-U",
        authDomain: "app.bigvu.tv"
    },
    tiktok: {
        clientId: "awok16gtpl9fta6l",
        redirectUrl: {
            origin: "https://develop.bigvu.tv",
            path: "auth/callback",
        },
    },
    linkedin: {
        clientId: "77ju366b5wzol3",
        liveClientId: "86s8gdw1jo75gg",
        redirectUrl: {
            origin: "https://develop.bigvu.tv",
            path: "auth/callback",
        },
    },
    googleApi: {
        redirectUrl: {
            origin: "https://develop.bigvu.tv",
            path: "auth/callback",
        },
        apiKey: "AIzaSyCxXQavTpcHrz9wYipjk-a5PSIuC5qcbOI",
        clientId: "310606674603-7ihhorcd4ov0533t973ma8nivgf7ii5r.apps.googleusercontent.com",
        scope: "https://www.googleapis.com/auth/youtubepartner https://www.googleapis.com/auth/youtube.force-ssl",
        appId:'reporter-12505'
    },
    dropboxApi: {
        redirectUrl: {
            origin: "https://develop.bigvu.tv",
            path: "auth/callback",
        },
        appKey: "9eirup5c3ajtgb3",
        accessToken: "sl.u.AFgSSxQOE8Z1hkAVlAX_fQ-JwENu1_fFRM5JLhDZpKt8-xhfV2jvoE6wnwbe7uFxFmwOpc5YuftvRdAoxPT5mI4hVI8uSzFR9zaMhsau12x1qUcdT-69VVQIwb-1gtXiivWSfhlbleVh11XJ3CH1hDZZnqGu2DXfIMCom7lFOedBf3iakqAUuZOY57TRQSEPmgFL9GigjVRpUBa5XMLMXwDkn6w2HmXHCnuFBytTNrSXo6vUUtk81M81SQ2SbbTloKVlUmrwVC7394CVqjfgooTihVNDdPqjd-mOrgDYQcQniwCNIz690c_Rpkomyo7UgsVxaM6chP1nbfa5upQCZYRks9GSE4A6IX2ba6dO7hw8geRYh8EUdjpotOU_MmZnO02ARy4F54eyX72LCKKtopVcKnZJNkSQ7mDFlhnO734Q-05yOJ9hjTAo3DROlEdQz6Mcd4pi5CpYdKF76dJ9DgypdLjtJ_nKk7t1-OAKTbiBNPpRSdiDAz9ag2skFio_RMCPT-VJqzr2LIn__EvNI-jcscmA_ptX4xHRnfWgDFoZWgAClLJVpJ-hUFw8jhUTU3GvkuyCI_zy_lXDBYMLHCycI8GRA7Ae8C2rCZg5NRqO8qecAk1jptscNSjrLkV0KUM1uesYJNzm912gzAzYjgny_dhgaOwY4QU400ddkxFXvNo7mG53ygAnELJC4Lh-RYRC47eVbzJbhpKtbNgZRwOxMnPf9bO2HD756XpSNJM7EipvcQ9SlsNOMIJO89dpCXDAyXCZwsO_E0phCKb02zM0JY7HIpJYW43PYlmajXcPHb6sI-D2Vipczqe3J8T11rWLWbxxGBN6YjRsGFMsriICjAmBRDuMm7JKZ0M7G_YCGb9fpzfbzHYvFZGHl6j_4PpXt2Lav0hx6LECglnakD5EZcidY10gc_aVZ2Dud5zaTsorGtKy0LGy8ykTw5I_O0GUbrUVzBVA06UjIfpqaI2OCvhnDntsBNo_DeYZI6SYk3E5ar_QRmc57lHp7IV_Hx1dLrCTOUTfE3vgwssW_UjjM_twNKgiM83s6Xc_SqY1Kvgyjrbto7kdfRK-Re8ulh2Y2-xbQXUeOU5ftNIYkPZCDSMRSsuvPodoovSeRtT3DC2wm4bYjbqVlHNCAxOsajdIRhuY8O4dav1Wq8qvHI1eNCcBRXr67DE_RXAuOesRyqhnNENxj49QYD86edEGpFhkqFnZ1Da6xn6EOmhYtkQhQOCMxcD0H8ycyzHFoXomV5NC3Ee65H4GYDSP5G8yeKQZQEwXs5VZzLchhQYcRAGc"
    },
    facebookApi: {
        version: 'v19.0',
        appId: "2406545966259984",
    },
    globals: {
        origin: "https://develop.bigvu.tv",
        websocket: "wss://web-socket-v4-dev.bigvu.tv:6550",
        stripe: "pk_test_JJLNzmPvx8PA2nzWSUqtynzq",
        qlikUserLink: "https://bigvu.us.qlikcloud.com/sense/app/c86a527f-671c-476d-a54e-c0db38bb69bb/sheet/ab704c3a-9f34-4e5d-a67f-9f97e408653c/state/analysis/options/clearselections/select/user_id/",
        host: "./api/",
        version: "v0.5/",
        debug: true,
        clientId: "Rzquwu6cANTgT4XeDHFw3ILj",
        firstAppPage: "boards",
        welcomeAppPage: "welcome",
        anonymousFirstPage: "boards",
        maxSlideDuration: 120,
        cloudfrontAddress: "https://assets-dev.bigvu.tv",
        cdnAddress: "./cdn",
        downloadAddress: "./download",
        hlsPlaceholderUrl: "https://assets-dev.bigvu.tv/defaults/outro/playlist.m3u8",
        pagesPreviewUrl: "https://demo-site.bigvu.tv/pages/",
        pageDraftPreviewUrl: "/page/preview/",
        emptyProfileImgUrl: "https://assets-dev.bigvu.tv/defaults/user_icon.png",
        mixpanelId: "40a039d18d76a9644619bdba57947e12",
        defaultFontPreviewText: "A shining crescent far beneath the flying vessel",
        shareLink: 'https://demo-my-video.bigvu.tv/',
        orphanedAccountUrl: '/profile/preferences',
    },
    teleprompter: {
        teleprompterOrigin: 'https://develop.bigvu.tv'
    },
    collections: {
        pageBaseUrl: 'https://demo-site.bigvu.tv/collections/',
        embedWidgetBaseUrl: 'https://demo-site.bigvu.tv/collection/',
        pollVoteUrl: 'https://api-dev.bigvu.tv/v0.5/poll/vote',
        onboardingDefaultAssetId: '611cbb7cee2eb56bc16d7c2e',
        appsumoSubscriptionUrl: 'https://appsumo.8odi.net/9WELjE',
    },
    appId: {
        hotjarAppId: 698198
    },
    serverless: {
        host: 'sls.api-dev.bigvu.tv',
    },
    localTakesDatabase: {
        version: 1,
    },
    appVersion: packageVersion.versionCode,
    version: packageVersion.version,
    shareLinkHost: "demo-my-video.bigvu.tv"
};
